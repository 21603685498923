import React from 'react';
import './home.css';

export default function Home() {
  return (
    <div className='page-container'>
        <div className='sub-container'>
            <h1 className='page-heading'> Welcome </h1>
            <div className='centered'>
                <img src='inprogress.jpg' alt='under_construction' className='portrait' />
            </div>
        </div>
    </div>
)}