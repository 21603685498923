import React, { useState } from 'react';
import './work.css';


export default function Work() {
    
    const data = [
        {
            id: "1",
            title: "Mosquito",
            desc: "Description / comment",
            img: "mosquito.jpg",
            overlay: "target-mosquito"
        },
        {
            id: "2",
            title: "Tribute to Bosch",
            desc: "Description / comment",
            img: "bosch.jpg",
            overlay: "target-bosch"
        },
        {
            id: "3",
            title: "Octopus",
            desc: "Description / comment",
            img: "octopus.jpg",
            overlay: "target-octopus"
        }
    ]

    const [clickedImg, setClickedImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);


  return (
    <div className='page-container'>
        <div className='sub-container'>
            <div className='centered'>
                {data.map((d, index) => (
                <div key={index} className="img-container">
                        <h2>{d.title}</h2>
                        <img 
                            src={d.img} 
                            alt={d.desc}
                            className='art'
                        />
                        <p className='art-description'>{d.desc}</p>
                </div>))}
            </div>    
        </div>    
    </div>
)}