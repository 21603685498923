import Banner from "./components/banner/Banner";
import Contact from  "./components/contact/Contact";
import Menu from "./components/menu/Menu";
import About from "./components/about/About";
import Home from "./components/home/Home";
import Work from "./components/work/Work";
import { BrowserRouter as Router, Route, Routes, Link, Switch } from 'react-router-dom';

import { useState } from "react";
import React from "react"


export default function App() {
  return (
    <Router>
      <div>
        <Banner />
        <Menu />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/Work" element={<Work />} />
            <Route path="/Contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  )
}