import "./menu.css";
import { Link } from 'react-router-dom';


export default function Menu() {
  return (
    <div className="menu" id='dropdown'>
      <ul>
        <li className="menu-block">
          <Link className='menu-option' to="/" style={{textDecoration:"none"}}>
            <span className='menu-option'>Home</span>
          </Link>
        </li>
        <li className="menu-block">
          <Link className='menu-option' to="/About" style={{textDecoration:"none"}}>
            <span className='menu-option'>About</span>
          </Link>
        </li>
        <li className="menu-block">
          <Link className='menu-option' to="/Work" style={{textDecoration:"none"}}>
            <span className='menu-option'>Work</span>
          </Link>
        </li>
        <li className="menu-block">
          <Link className='menu-option' to="/Contact" style={{textDecoration:"none"}}>
            <span className='menu-option'>Contact</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}