import React from 'react';
import './contact.css';

export default function Contact() {
    return (
        <div className='page-container'>
            <div className='sub-container'>
                <h1 className='page-heading'> Contact </h1>
                <div className='centered'>
                <p className='form-about'> Please submit all inquiries below. I will get back to you at my earliest convenience.</p>
                    <form className='contact-form' name="contact-v2" method="post" data-netlify="true">
                        <input className='form-input' type="hidden" name="form-name" value="contact-v2" />
                        <label className='form-label'>
                            <input className='form-input' required type="text" name="email" placeholder=" Your Email Address" />
                        </label>
                        <label className='form-label'>
                            <input className='form-input' required type="text" name="name" placeholder=" Your Name" />
                        </label>    
                        <label className='form-label'>
                            <textarea className='form-message' required name="message" placeholder= "Message"></textarea>
                        </label>        
                        <button className= 'form-button' type= "submit"> Submit </button>
                    </form>
                </div>
            </div>    
        </div>
    )}