import React, { useRef, useEffect, useState } from "react";
import "./banner.css";
import { Link } from 'react-router-dom';

export default function Banner() {

    useEffect(() => {
        const menuBtn = document.getElementById('menu-btn');
        const dropDown = document.getElementById('dropdown');
        let buttonOpen = false;
        
        /* open menu */
        menuBtn.addEventListener('click', () => {
            if(!buttonOpen) {
                menuBtn.classList.add('open');
                dropDown.style.top = '70px';
                buttonOpen = true;
            } else {
                menuBtn.classList.remove('open');
                dropDown.style.top = '-470px';
                buttonOpen = false;
            }
        });

        /* reset menu on option click */
        const menuOption = document.querySelectorAll('.menu-option');
        menuOption.forEach(item => {
            item.addEventListener('click', () => {
                        menuBtn.classList.remove('open');
                        dropDown.style.top = '-470px';
                        buttonOpen = false;
            });
        });
    });

    return (
        <div className="topbar">
            <div className='top-nav'>
            <ul className="banner-list">
            <li className="banner-item">
                <Link to="/" style={{textDecoration:"none"}}>
                    <span className="menu-text"> Home </span>
                </Link>
            </li>
            <li className="banner-item">    
                <Link to="/About" style={{textDecoration:"none"}}>
                    <span className="menu-text"> About </span>    
                </Link>
            </li>
            <li className="banner-item">
                <Link to="/Work" style={{textDecoration:"none"}}>
                    <span className="menu-text"> Work </span>    
                </Link>
            </li>
            <li className="banner-item">   
                <Link to="/Contact" style={{textDecoration:"none"}}>
                    <span className="menu-text"> Contact </span>    
                </Link>
            </li>     
            </ul>    
            </div>
            <div className="menu-title">
                    <img src='signature.png' className='signature' alt=''/>
            </div>
            <div className="menu-btn" id="menu-btn">
                <div className="menu-btn_burger">
                </div>
            </div>
        </div>
    )
}
