import React from 'react';
import './about.css';

export default function About() {
    return (
        <div className='page-container'>
            <div className='sub-container'>
                <h1 className='page-heading'> About </h1>
                <div className='about-centered'>
                    <div className='about-left' id='one'>
                        <img src='portrait.jpg' alt='portrait' className='portrait' />
                    </div>
                    <div className='about-right' id='two'>
                        <p className='about-text'>
                            

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ullamcorper semper eleifend. Maecenas tempus metus erat, vel interdum ipsum bibendum sit amet. Proin consectetur ultrices purus venenatis luctus. Suspendisse vehicula tristique nisi, vitae ultrices dolor lacinia eu. Donec pharetra purus in faucibus tristique. Vestibulum ut tempus nisi. Nulla quis eleifend diam.

Curabitur fringilla a erat ac tincidunt. Sed convallis libero quam, quis fringilla odio imperdiet nec. Aliquam tempus ornare justo in vulputate. Cras efficitur venenatis semper. Vestibulum fermentum tortor id varius dapibus. Nulla a placerat lorem. Mauris rhoncus commodo tempor. Phasellus sed accumsan urna, in tincidunt sem. Mauris maximus risus luctus elit bibendum, id imperdiet odio laoreet.

                        
                        
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )}